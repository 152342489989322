import React, { useState, useEffect, Fragment, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Transition,TransitionChild,
  Listbox,
  ListboxButton, ListboxOption, ListboxOptions,
  Combobox,ComboboxInput,ComboboxButton,ComboboxOptions,ComboboxOption,
  Switch,
  Dialog,DialogPanel,
} from "@headlessui/react";
import UtilService from "../../services/utilService";
import { DateRangePicker } from "react-date-range";
import Notification, {
  showNotification,
} from "../../common/notification/notification";
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import Loader from "../../common/loader/loader";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import TaskDetail from "./taskDetail";
import History from "./history";
import useAuthToken from "../../services/api";
import { useTabContext, useTaskEvent } from "../../contexts/TaskEventContext";
import Pagination from "../../common/Pagination";
import Select from 'react-select';

function Task() {
  const { callApiWithToken, callApi } = useAuthToken();
  const [showLoader, setshowLoader] = useState(false);
  const emailId = localStorage.getItem("userEmail");
  const params = new URLSearchParams(window.location.search);
  const [tasks, setTasks] = useState([]);
  const [count, setCount] = useState(0);
  const [assignedByUsers, setAssignedByUsers] = useState([]);
  const [assignedToUsers, setAssignedToUsers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [openHistory, setopenHistory] = useState(false);
  const [openHistoryMobile, setopenHistoryMobile] = useState(false);
  const [historyTaskId, setHistoryTaskId] = useState(null);
  const navigate = useNavigate();
  const { subscribe } = useTaskEvent();
  const { selectedTab, setSelectedTab, tabNames, setTabNames } =
    useTabContext();
  const [filterModeOn, setFilterModeOn] = useState(false);
  const [page, setPage] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [open, setOpen] = useState(false);
  const [openTaskDetailMobile, setOpenTaskDetailMobile] = useState(false);
  const [openTaskId, setopenTaskId] = useState(null);
  const [buList, setBUList] = useState([]);
  let size = 10;
  // console.log('selectedTab',selectedTab)

  const initialDateRange = [
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ];

  const [dateRange, setDateRange] = useState(initialDateRange);
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [selectedAssignedTo, setSelectedAssignedTo] = useState(null);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedBUs, setSelectedBUs] = useState([]);
  const [enabled, setEnabled] = useState(false);
  const [onBehalfEnabled, setOnBehalfEnabled] = useState(false);
  const [taskDetailModalOpen, setTaskDetailModalOpen] = useState(false);
  const dialogRef = useRef(null);
  const [filters, setFilters] = useState({
    assignedBy: null,
    assignedTo: null,
    creator:null
  });
  const [statusList, setStatusList] = useState([]);
  const [notification, setNotification] = useState(null);
  const [isTabSet, setIsTabSet] = useState(false);
  const [filteredAssignees, setFilteredAssignees] = useState(null);
  const [filteredAssignedTos, setFilteredAssignedTos] = useState(null);
  const [showPicker, setShowPicker] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);
  const [buOptions, setBuOptions] = useState([]);
  const [assignOnBehalfCol, setAssignOnBehalfCol] = useState(false);
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 44,
      borderColor: "#D1D5DB", // Matches `ring-gray-300`
      boxShadow: "none",
      borderRadius:'6px',
      fontSize:'14px',
      
    }),
    multiValue: (base, state) => ({
      ...base,
      backgroundColor: state.data.color, // Use the color from the API data
    }),
    multiValueLabel: (base, state) => ({
      ...base,
      color: state.data.textColor,
       // Use the text color from the API data
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      color: state.data.textColor,
      ":hover": {
        backgroundColor: state.data.color,
        color: state.data.textColor,
      },
    }),
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // get data for Assinged By filter
  const fetchAssignedByUsers = async () => {
    try {
      const users = await callApi(
        `${process.env.REACT_APP_SERVER_URL}task/get/assigned-by/list`,
        {}
      );
      setAssignedByUsers(users.data);
      setFilteredAssignees(users.data);
    } catch (error) {}
  };

  // get data for Assinged To filter
  const fetchAssignedToUsers = async () => {
    try {
      const users = await callApi(
        `${process.env.REACT_APP_SERVER_URL}task/get/assigned-to/list`,
        {}
      );
      setAssignedToUsers(users.data);
      setFilteredAssignedTos(users.data);
    } catch (error) {}
  };

  const fetchStatusList = async () => {
    try {
      const response = await callApi(
        `${process.env.REACT_APP_SERVER_URL}status/list`
      );
      setStatusList(response);
       /*for mobile*/
     const options = response.map(status => ({
      value: status.statusName,
      label: status.statusName,
      color: status.color,
      textColor: status.textColor,
    }));
    setStatusOptions([{ value: "Select All", label: "Select All" }, ...options]);
     
    } catch (error) {
      console.error("Error fetching statuses:", error);
    }
    
    
  };

  const fetchBUList = async () => {
    try {
      const response = await callApiWithToken(
        `${process.env.REACT_APP_SERVER_URL}business/getAll`
      );
      setBUList(response.data);
      // console.log('BU list', response.data)
      const buOptions = response.data.map(bu => ({
        value: bu.businessId,
        label: bu.businessName
      }));
      setBuOptions([{ value: "Select All", label: "Select All" }, ...buOptions]);
    } catch (error) {
      console.error("Error fetching statuses:", error);
    }
    
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    setFilterModeOn(true);
    setFilters({ ...filters,page:newPage - 1, searchTerm: filters.searchTerm });
    navigate("/tasks");
    setTaskDetailModalOpen(false);
  };

  const fetchTasks = async () => {
    // console.log('page >>>',page)
    setshowLoader(true);
    
    const requestBody = { ...filters, size: size, page: page - 1 };

    if (selectedTab === 0) {
      requestBody["assignedTo"] = emailId;
    } else if(selectedTab === 1){
      if (onBehalfEnabled && emailId) {
        requestBody["creator"] = emailId;
      }else{
        requestBody["assignedBy"] = emailId;
      }
    } else{
      return;
    }
    
    if(requestBody['isRead']) { delete requestBody['isRead']; }
    try {
      const res = await callApiWithToken(
        `${process.env.REACT_APP_SERVER_URL}task/list`,
        {
          method: "POST",
          body: JSON.stringify(requestBody),
        }
        
      );
      // console.log('res',res)
      setshowLoader(false);
      if (res.success && res.data) {
        showNotification(setNotification, "success", res.message)
        const taskList = (res.data["tasks"]).map(task => {
          
          if (task.updatedDueDate) {
            return { ...task, dueDate: task.updatedDueDate.value };
          } 
          return task;
        });
       
        setTasks(taskList);
        
        if(taskList.length ===0){
          showNotification(setNotification, "warning", 'No records Found')
        }
        setCount(res.data["count"]);
        setTabNames(
          tabNames.map((tab) => {
            if (tab.id === 0) {
              return { ...tab, count: res.data["countAssignedTo"].toString() };
            } else if (tab.id === 1) {
              return { ...tab, count: res.data["countAssignedBy"].toString() };
            }
            return tab;
          })
        );
      }
    } catch (error) {
      setTasks([])
      setshowLoader(false);
      // console.error("Error fetching tasks:", error);
    }
  };

  const resetFilters = async () => {
    navigate("/tasks");
    setTaskDetailModalOpen(false);
    setSelectedTaskId(null);
    setSelectedAssignee("");
    setSelectedAssignedTo("");
    setSelectedStatuses([]);
    setSelectedBUs([]);
    setSearchValue("");
    setDateRange(initialDateRange);
    setFilters({
      assignedBy: null,
      assignedTo: null, 
      creator:null, 
      startDate: null,
      endDate: null,
    });
    setPage(1);
    setEnabled(false)
    setFilterModeOn(false);
    setOnBehalfEnabled(false)
  }
  const handleTabChange = async (index) => {
    await setSelectedTab(index);
    resetFilters();
  };


  const handleStatusChange = async (event, task) => {
    // console.log('selectedAssignedTo',event.target.value,taskId)
    const statusNumber = Number(event.target.value);
    setshowLoader(true);
    let requestBody = {
        taskId:task.taskId,
          title: task.title,
          description: task.description,
          dueDate: task.dueDate,
          updatedDueDate: task.updateDueDate,
          attachments: task.attachments,
          creator: task.creator,
          assignedTo:task.assignedTo,
          assignedBy:task.assignedBy,
          creationDate:task.creationDate,
          modifiedDate:task.modifiedDate,
          isRead: task.isRead,
          status: statusNumber,
          businessId:task.business ? task.business.businessId : null,
    }
    const res = await callApiWithToken(
      `${process.env.REACT_APP_SERVER_URL}task/update/${task.taskId}`,
      {
        method: "PUT",
        body: JSON.stringify(
          requestBody
         ),
      }
    );
    setshowLoader(false);
    if (res.success && res.data) {
      showNotification(
        setNotification,
        "success",
        "Status updated successfully!"
      );
      await refetchTaskList();

    }
    // console.log('tasks',tasks)
  };


  const handleStatusFilterChange = (selected) => {
    // console.log('selected',selected,statusList)
    // Need to set the page to 1 which will set to 0 by fetchTasks
    setPage(1);
    setFilterModeOn(true);
    if (selected.includes("Select All")) {
      if (selected.length === statusList.length + 1 ) {
        setSelectedStatuses([]);
      } else {
        let statusNameList = statusList.map((s) => s.statusName);
        if (selectedStatuses.length !== 0) {
          setFilters({ ...filters, statuses: statusNameList });
        }
        setSelectedStatuses(statusNameList);
      }
    } else {
      setSelectedStatuses(selected);
      setFilters({ ...filters, statuses: selected});
      showNotification(
        setNotification,
        "success",
        "Status selected!"
      );
      navigate('/tasks')
      setTaskDetailModalOpen(false)
    }
    // console.log('filetrs status', filters)
  };
  
  const handleStatusFilterChangeMobile = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setFilterModeOn(true);
     // Need to set the page to 1 which will set to 0 by fetchTasks
     setPage(1);
    if (selectedValues.includes("Select All")) {
      if (selectedValues.length === statusOptions.length) {
        // If "Select All" is selected and the number of selected options equals the total options (including "Select All"), deselect all
        setSelectedStatuses([]);
        setFilters({ ...filters, statuses: [] });
        navigate('/tasks')
        setTaskDetailModalOpen(false)
      } else {
        // Otherwise, select all statuses
        const statusNameList = statusOptions.map((option) => option.value);
        
        if (selectedStatuses.length !== 0) {
          setFilters({ ...filters, statuses: statusNameList});
          navigate('/tasks')
          setTaskDetailModalOpen(false)
        }
        
        setSelectedStatuses(statusNameList);
      }
    } else {
      // Handle individual status selection
      setSelectedStatuses(selectedValues);
      setFilters({ ...filters, statuses: selectedValues });
      navigate('/tasks')
      setTaskDetailModalOpen(false)
      showNotification(
        setNotification,
        "success",
        "Status selected!"
      );
    }
  };
  


  const isAllSelected = selectedStatuses.length === statusList.length;


  const handleAssignedByFilterChange = (event) => {
    // Need to set the page to 1 which will set to 0 by fetchTasks
    setPage(1);
    if (event) {
      setFilterModeOn(true);
      setInputValue("");
      setSelectedAssignee(event);
      setFilters({
        ...filters,
        assignedBy: event
      });
      navigate('/tasks')
      setTaskDetailModalOpen(false)
    }
  };
  // input box for Assigned By filter
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    if (event.target.value === "") {
      setSelectedAssignee(""); // Clear the selected value if input is cleared
      setFilters({
        ...filters,
        assignedBy: null
      });
    }
    
  };

  // input box for Assigned To filter
  const handleInput2Change = (event) => {
    setInputValue2(event.target.value);
    if (event.target.value === "") {
      setSelectedAssignedTo(""); // Clear the selected value if input is cleared
      // resetFilters()
      setFilters({
        ...filters,
        assignedTo: null
      });
    }
  };

  const handleAssignedToFilterChange = (event) => {
    // console.log('event',event)
    // Need to set the page to 1 which will set to 0 by fetchTasks
    setPage(1);
    if(event) {
      setFilterModeOn(true);
      setInputValue2("");
      setSelectedAssignedTo(event);
      setFilters({
        ...filters,
        assignedTo: event,
      });
      navigate('/tasks')
      setTaskDetailModalOpen(false)
    }
  };


  const filterTasksByIdNameDesc = (event) => {
    event.preventDefault();
    setSearchValue(event.target.value);
    
    let key = event.target.value;
    key.length > 0 ? setFilterModeOn(true) : setFilterModeOn(false);
    if (key.length > 3 || key.length === 0) {
      // Need to set the page to 1 which will set to 0 by fetchTasks
      setPage(1);
      setFilters({
        ...filters,
        searchTerm: key
      });
      navigate('/tasks')
      setTaskDetailModalOpen(false)
    }
  };


  const handleSelect = (ranges) => {
    setFilterModeOn(true);
    setDateRange([ranges.selection]);
    
  };

  const togglePicker = () => {
    setFilterModeOn(true);
    setShowPicker(!showPicker);
    
  };

  

  const handleApply = () => {
    togglePicker(); // Close the date picker on Apply
    if (dateRange[0].startDate && dateRange[0].endDate) {
      setFilters({
        ...filters,
        startDate: dateRange[0].startDate.getTime(),
        endDate: dateRange[0].endDate.getTime(),
      });
      navigate('/tasks')
      setTaskDetailModalOpen(false)
    }
  };

  const handleClear = () => {
    togglePicker();
    setDateRange(initialDateRange);
    // resetFilters()
    setFilters({
      ...filters,
      startDate: null,
      endDate: null,
    })
  }

  const handleReadToggle = (event) => {
    setEnabled(event);
    setFilterModeOn(true);
    setFilters({
      ...filters,
      isRead: !event
    })
    navigate('/tasks')
    setTaskDetailModalOpen(false)
  }
  const handleAssignOnBehalfFilterChange = (event)=>{
    setOnBehalfEnabled(event)
    setFilterModeOn(true)
    // Need to set the page to 1 which will set to 0 by fetchTasks
    setPage(1);
    if (event) {
      // If the "On Behalf" filter is enabled, set the appropriate filters
      setFilters({
        ...filters,
        creator: emailId,
        assignedBy: null
      });
      setAssignOnBehalfCol(true);
      navigate('/tasks')
      setTaskDetailModalOpen(false)
    } else {
      // If the "On Behalf" filter is disabled, reset the filters
      setFilters({
        ...filters,
        creator: null
      });
      navigate('/tasks')
      setTaskDetailModalOpen(false)
      setAssignOnBehalfCol(false)
    }
    
  }
  

  const markAsRead = async (taskToUpdate) => {
    setSelectedTaskId(taskToUpdate.taskId);
    if (!taskToUpdate.isRead) {
      const result = await callApiWithToken(
        `${process.env.REACT_APP_SERVER_URL}task/${taskToUpdate.taskId}/mark-read`
      );
      if (result.success) {
        updateTaskList(taskToUpdate);
      }
    }
  };

  const handleTaskDetailModalChange = (task) => {
    setTaskDetailModalOpen(true);
    let url;
    if (isTabSet) {
      url = `/tasks?id=${task.taskId}&tab=${selectedTab}`;
    } else {
      url = `/tasks?id=${task.taskId}`;
    }
    navigate(url);
  };
  const handleTaskDetailModalChangeMobile = (task) => {
    setOpenTaskDetailMobile(true);
    let url;
    if (isTabSet) {
      url = `/tasks?id=${task.taskId}&tab=${selectedTab}`;
    } else {
      url = `/tasks?id=${task.taskId}`;
    }
    navigate(url);
  };

  const updateTaskList = (taskToUpdate) => {
    // Create a new array with updated task
    const updatedTasks = tasks.map((task) => {
      if (task.taskId === taskToUpdate.taskId) {
        return { ...task, isRead: true };
      }
      return task;
    });
    // Update state with the new array
    setTasks(updatedTasks);
  };

  const handleBuFilterChange = (selected) => {
    // console.log('selected', selected);
    setFilterModeOn(true);
    // Need to set the page to 1 which will set to 0 by fetchTasks
    setPage(1);
    if (selected.includes("Select All")) {
      if (selected.length === buList.length + 1) {
        setSelectedBUs([]);
        setFilters({ ...filters, businessId: [] });
        // navigate('/tasks')
        setTaskDetailModalOpen(false)
      } else {
        const buIdList = buList.map((s) => s.businessId);
        setSelectedBUs(buIdList);
        setFilters({ ...filters, businessId: buIdList});
        // navigate('/tasks')
        setTaskDetailModalOpen(false)
      }
    } else {
      setSelectedBUs(selected);
      setFilters({ ...filters, businessId: selected });
      navigate('/tasks')
      setTaskDetailModalOpen(false)
    }
    
    const filtered = tasks.filter((task) => {
      if (!task.business) return false;
      const taskBusinessId = task.business.businessId;
      return selected.includes(taskBusinessId);
    });
  
    setTasks(filtered);
    setTaskDetailModalOpen(false)
    // console.log('filtered >>>', filtered, tasks);
  };
  

  const handleBuFilterChangeMobile = (selectedOptions) => {
      const selectedValues = selectedOptions.map((option) => option.value);
      setFilterModeOn(true);
      // Need to set the page to 1 which will set to 0 by fetchTasks
      setPage(1);
      if (selectedValues.includes("Select All")) {
        if (selectedValues.length === buOptions.length) {
          // If "Select All" is selected and the number of selected options equals the total options (including "Select All"), deselect all
          setSelectedBUs([]);
          setFilters({ ...filters, businessId: [] });
        } else {
          // Otherwise, select all statuses
          const buNameList = buOptions.map((option) => option.value);
          // console.log('buNameList',buNameList)
          
          if (selectedBUs.length !== 0) {
            setFilters({ ...filters, businessId: buNameList});
          }
          
          setSelectedBUs(buNameList);
        }
      } else {
        // Handle individual status selection
        setSelectedBUs(selectedValues);
        setFilters({ ...filters, businessId: selectedValues});
        
        showNotification(
          setNotification,
          "success",
          "BU selected!"
        );
      }
    };

    useEffect(() => {
      // console.log('fetch task subscribed!')
      const unsubscribe = subscribe(fetchTasks);
      return unsubscribe;
    }, [subscribe]);
  
    const refetchTaskList = async () => {
      await fetchTasks();
    };
  
    useEffect(() => {
      if (!emailId) navigate("/");
      // Check if there is an 'id' parameter in the URL
      const id = params.get("id");
      const tab = parseInt(params.get("tab"));
      if (tab === 0 || tab === 1) {
        setSelectedTab(tab);
        setIsTabSet(true);
      } else {
        setIsTabSet(false);
      }
      if (id) {
        setSelectedTaskId(id);
        setTaskDetailModalOpen(true);
      }
    }, [emailId, navigate, params]);
  
    useEffect(() => {
      fetchStatusList();
      fetchBUList();
      fetchAssignedByUsers();
      fetchAssignedToUsers();
    }, []);
  
    useEffect(() => {
      if ((params.get("tab") && isTabSet) || !params.get("tab")) {
        fetchTasks();
      }
    }, [filters, isTabSet, selectedTab, emailId, onBehalfEnabled]);
  
  
    useEffect(() => {
      // Filter the options based on the input value
      setFilteredAssignees(
        assignedByUsers.filter((assignee) =>
          assignee.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
      setFilteredAssignedTos(
        assignedToUsers.filter((user) =>
          user.toLowerCase().includes(inputValue2.toLowerCase())
        )
      );
    }, [inputValue, assignedByUsers,inputValue2, assignedToUsers]);
  
   
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dialogRef.current && !dialogRef.current.contains(event.target)) {
          setShowPicker(false);
        }
      };
      if (showPicker) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [showPicker]);
    
    // console.log('tasks', tasks)
  
  return (
    <>
    {showLoader && (
      <div className="fixed bottom-0 bg-[#00000024] z-[101] top-0 right-0 left-0 ">
        <div className="h-full w-full flex items-center justify-center">
          <Loader visible={showLoader} />
        </div>
      </div>
    )}
      <Notification type={notification?.type} message={notification?.message} />
      
      <div className="sticky sm:relative left-0 right-0 px-[10px] bg-white pt-[8px] sm:mt-[60px] sm:top-auto top-[51px] z-[3] ">
       
        {/* tab start  */}
        <nav className="flex space-x-4 sm:w-full" aria-label="Tabs">
          {tabNames.map((tab, index) => (
            <a
              key={tab.name}
              href={tab.href}
              onClick={() => handleTabChange(index)}
              className={classNames(
                index === selectedTab
                  ? "bg-atom_onxyblack text-white  font-medium [box-shadow:0px_3px_6px_0px_#00000000]"
                  : "text-atom_onxyblack bg-white border border-atom_silver hover:text-gray-700 font-medium [box-shadow:0px_3px_6px_0px_#00000025]",
                "rounded-t-md p-[8px] text-xs text-center  cursor-pointer "
              )}
              aria-current={index === selectedTab ? "page" : undefined}
            >
              {tab.name}
              <span
                className={classNames(
                  index === selectedTab
                    ? "bg-white border-0 "
                    : "text-atom_onxyblack bg-gray-100",
                  "inline-flex items-center rounded-full ml-2 h-[20px] w-[20px] justify-center text-xs font-medium text-gray-600"
                )}
              >
                {" "}
                {tab.count}
              </span>
            </a>
          ))}
        </nav>
        {/* tab end  */}
      </div>

      {/* filters start */}
      <div className="px-[10px] top-[97px] pt-[10px] z-[10] sticky bg-white flex right-0 left-0 border-t border-[#D7D7DC] sm:hidden">
        <form className="flex w-full items-center" action="">
          {/* search start */}
          <div className="relative rounded-md shadow-sm mr-3 w-[155px]">
            <input
              type="text"
              name="search"
              id="search"
              className="block w-full text-[12px] rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-atom_rojored sm:text-sm sm:leading-6"
              placeholder="Search"
              value={searchValue}
              onChange={(event) => filterTasksByIdNameDesc(event)}
            />
            <div className="pointer-events-none absolute right-0 flex items-center pr-3 top-[8px]">
              <svg
                className="h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            </div>
          </div>
          {/* search end */}

          {/* assign by start */}
          {selectedTab === 0 && (<Combobox
            as="div"
            value={selectedAssignee}
            onChange={handleAssignedByFilterChange}
            >
            <div className="relative rounded-md shadow-sm mr-3 w-[155px]">
              <ComboboxInput
                className="w-full rounded-md border-0 bg-white text-[12px] py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-atom_rojored sm:text-sm sm:leading-6"
                placeholder="Assigned By"
                onChange={handleInputChange}
                defaultValue = ""
                displayValue={(selectedAssignee) => selectedAssignee ? (selectedAssignee === emailId ? "Me" : selectedAssignee) : ''}
                />
                <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </ComboboxButton>

              {filteredAssignees && filteredAssignees.length > 0 && (
                <ComboboxOptions className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {filteredAssignees.map((assignee, i) => (
                    <ComboboxOption
                      key={i}
                      value={assignee}
                      className={({ active }) =>
                        classNames(
                          "relative cursor-default text-[12px] select-none py-2 pl-3 pr-9",
                          active ? "bg-atom_rojored text-white" : "text-gray-900"
                        )
                      }
                    >
                      {({ active, selected }) => (
                        <>
                          <span
                            className={classNames(
                              "block truncate",
                              selected && "font-semibold"
                            )}
                            title={assignee}
                          >
                          {assignee === emailId ? "Me" : assignee}
                          </span>

                          {selected && (
                            <span
                              className={classNames(
                                "absolute inset-y-0 right-0 flex items-center pr-4",
                                active ? "text-white" : "text-atom_rojored"
                              )}
                              title={assignee}
                            >
                              {assignee === emailId ? "Me" : assignee}
                            </span>

                           
                            )}
                          </>
                        )}
                      </ComboboxOption>
                    ))}
                  </ComboboxOptions>
                )}
              </div>
            </Combobox>
          )}
          {/* assign by end */}

          {/* assign to start */}
          {selectedTab === 1 && (<Combobox
            as="div"
            value={selectedAssignedTo}
            onChange={handleAssignedToFilterChange}
          >
            <div className="relative rounded-md shadow-sm mr-3 w-[155px]">
              <ComboboxInput
                className="w-full rounded-md border-0 text-[12px] bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-atom_rojored sm:text-sm sm:leading-6"
                placeholder="Assigned To"
                onChange={handleInput2Change}
                defaultValue = "" 
                displayValue={selectedAssignedTo ? selectedAssignedTo : ''}
                />
                <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </ComboboxButton>

              {filteredAssignedTos && filteredAssignedTos.length > 0 && (
                <ComboboxOptions className="absolute z-[99] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {filteredAssignedTos.map((user, i) => (
                    <ComboboxOption
                      key={i}
                      value={user}
                      className={({ active }) =>
                        classNames(
                          "relative cursor-default text-[12px] select-none py-2 pl-3 pr-9",
                          active ? "bg-atom_rojored text-white" : "text-gray-900"
                        )
                      }
                    >
                      {({ active, selected }) => (
                        <>
                        {!selected && (
                          <span
                            className={classNames(
                              "block truncate",
                              selected && "font-semibold"
                            )}
                            title={user}
                          >
                          {user}
                          </span>
                          )}

                          {selected && (
                            <span
                              className={classNames(
                                "block truncate inset-y-0 right-0 items-center",
                                active ? "text-white" : "text-atom_rojored"
                              )}
                              title={user}
                            >
                              {user}
                            </span>

                            )}
                          </>
                        )}
                      </ComboboxOption>
                    ))}
                  </ComboboxOptions>
                )}
              </div>
            </Combobox>
          )}
         

          {/* Status  by start */}
          
          <div className="relative z-[99] w-[155px] mr-3">
          <Listbox value={selectedStatuses}
            onChange={handleStatusFilterChange} multiple>
            
            <ListboxButton className="relative  h-[36px] cursor-pointer text-[12px] w-full rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-atom_rojored sm:text-sm sm:leading-6">
            <span className="block truncate" title={selectedStatuses.length === 0 ? "Select Status" : selectedStatuses.join(', ')}>
                    {selectedStatuses.length === 0 ? "Select Status" : selectedStatuses.join(', ')}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
              </ListboxButton>
            <ListboxOptions anchor="bottom" className="bg-white z-[11] w-[155px] py-1 border-[1px] listOptionHeight rounded-md overflow-auto">
            <ListboxOption
                      key="all"
                      className={({ selected }) =>
                        classNames(
                          selected ? "bg-atom_rojored text-white" : "text-gray-900",
                          "relative group hover:bg-atom_rojored hover:text-white cursor-default text-[12px] select-none py-2 pl-3 pr-9"
                        )
                      }
                      value="Select All"
                    >
                      {({selected }) => (
                        <>
                          <span className={classNames(isAllSelected ? "font-semibold" : "font-normal", "block truncate")}>
                            Select All
                          </span>
                          {isAllSelected && (
                            <span className={classNames(selected ? "text-atom_rojored" : "", "group-hover:text-white absolute text-[12px] inset-y-0 right-0 flex items-center pr-4")}>
                              <CheckIcon className="h-4 w-4" aria-hidden="true" />
                            </span>
                          )}
                        </>
                      )}
            </ListboxOption>
            {statusList.map((status, s) => (
                <ListboxOption key={s} value={status.statusName} className={({ selected }) =>
                  classNames(
                    selected
                      ? " text-atom_rojored"
                      : "text-gray-900",
                    "relative cursor-default group hover:bg-atom_rojored hover:text-white text-[12px] select-none py-2 pl-3 pr-9"
                  )
                }>
                  {({selected }) => (
                           <>
                             <span
                               className={classNames(
                                 selectedStatuses.includes(status.statusName)
                                   ? "font-semibold"
                                   : "font-normal",
                                 "block truncate"
                               )}
                             >
                               {status.statusName}
                             </span>
                             {selectedStatuses.includes(status.statusName) && (
                               <span
                                 className={classNames(
                                  selected ? "text-atom_rojored" : "",
                                   "group-hover:text-white absolute inset-y-0  text-[12px] right-0 flex items-center pr-4"
                                 )}
                               >
                                 <CheckIcon
                                   className="h-4 w-4"
                                   aria-hidden="true"
                                 />
                               </span>
                             )}
                           </>
                         )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Listbox>
          </div>
          
          {/* {BU list started} */}
         
    <div className="relative w-[155px] mr-3">
          <Listbox value={selectedBUs} onChange={handleBuFilterChange} multiple>
            
            <ListboxButton className="relative  h-[36px] cursor-pointer text-[12px] w-full rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-atom_rojored sm:text-sm sm:leading-6">
            <span className="block truncate" title={selectedBUs.length === 0 ? "Select BU" : selectedBUs.map(buId => buList.find(bu => bu.businessId === buId)?.businessName).join(', ')}>
            {selectedBUs.length === 0 ? "Select BU" : selectedBUs.map(buId => buList.find(bu => bu.businessId === buId)?.businessName).join(', ')}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
              </ListboxButton>
            <ListboxOptions anchor="bottom" className="bg-white w-[155px] z-[15] py-1 border-[1px] listOptionHeight rounded-md overflow-auto">
            <ListboxOption
                      key="all"
                      className={({ selected }) =>
                        classNames(
                          selected ? "bg-atom_rojored text-white" : "text-gray-900",
                          "relative group hover:bg-atom_rojored hover:text-white cursor-default text-[12px] select-none py-2 pl-3 pr-9"
                        )
                      }
                      value="Select All"
                    >
                      {({selected }) => (
                        <>
                          <span className={classNames(isAllSelected ? "font-semibold" : "font-normal", "block truncate")}>
                            Select All
                          </span>
                          {isAllSelected && (
                            <span className={classNames(selected ? "text-atom_rojored" : "", "group-hover:text-white absolute text-[12px] inset-y-0 right-0 flex items-center pr-4")}>
                              <CheckIcon className="h-4 w-4" aria-hidden="true" />
                            </span>
                          )}
                        </>
                      )}
            </ListboxOption>
            {buList.map((bu) => (
                <ListboxOption key={bu.businessId} value={bu.businessId} className={({ selected }) =>
                  classNames(
                    selected
                      ? " text-atom_rojored"
                      : "text-gray-900",
                    "relative group cursor-default hover:bg-atom_rojored hover:text-white text-[12px] select-none py-2 pl-3 pr-9"
                  )
                }>
                  {({selected }) => (
                           <>
                             <span
                               className={classNames(selectedBUs.includes(bu.businessId)
                                   ? "font-semibold"
                                   : "font-normal",
                                 "block truncate"
                               )}
                             >
                               {bu.businessName}
                             </span>
                             {selectedBUs.includes(bu.businessId) && (
                               <span
                                 className={classNames(
                                  selected ? "text-atom_rojored" : "",
                                   "group-hover:text-white  absolute inset-y-0  text-[12px] right-0 flex items-center pr-4"
                                 )}
                               >
                                 <CheckIcon
                                   className="h-4 w-4"
                                   aria-hidden="true"
                                 />
                               </span>
                             )}
                           </>
                         )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Listbox>
          </div>

          
          {/* <div className="mt-2 flex justify-between">
            <button
              onClick={applySelection}
              className="bg-atom_rojored text-white py-1 px-3 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-atom_rojored"
            >
              Apply
            </button>
            <button
              onClick={clearSelection}
              className="bg-gray-300 text-gray-700 py-1 px-3 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
            >
              Clear
            </button>
          </div> */}
          {/* Status by end */}

          {/* Due Date  start */}
          <div className="w-[155px] mr-3">
            <div className="relative " onClick={togglePicker}>
            <input
              type="text"
              className="block w-full text-[12px] z-0 rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-atom_rojored sm:text-sm sm:leading-6"
              placeholder="Due Date"
              value={(dateRange[0].startDate && dateRange[0].endDate) ? `${UtilService.formatDate(dateRange[0].startDate)} - ${UtilService.formatDate(dateRange[0].endDate)}`: ''}
              readOnly
            />
            <div className=" absolute z-[10] cursor-pointer inset-y-0 right-0 flex items-center pr-3">
          {/* <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> */}
          <svg className="h-4 w-4 text-gray-40 " width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.50002 0.833344V2.50001H11.5V0.833344H13.1667V2.50001H16.5C16.9603 2.50001 17.3334 2.87311 17.3334 3.33334V16.6667C17.3334 17.1269 16.9603 17.5 16.5 17.5H1.50002C1.03979 17.5 0.666687 17.1269 0.666687 16.6667V3.33334C0.666687 2.87311 1.03979 2.50001 1.50002 2.50001H4.83335V0.833344H6.50002ZM15.6667 9.16668H2.33335V15.8333H15.6667V9.16668ZM5.66669 11.6667V13.3333H4.00002V11.6667H5.66669ZM14 11.6667V13.3333H7.33335V11.6667H14ZM4.83335 4.16668H2.33335V7.50001H15.6667V4.16668H13.1667V5.83334H11.5V4.16668H6.50002V5.83334H4.83335V4.16668Z" fill="#3C3C3C"></path></svg>
        </div>
          </div>
          {showPicker && (
           <div className="absolute z-[999] shadow-lg pb-[10px] bg-white flex flex-col"   ref={dialogRef}> 
          
              <DateRangePicker 
                ranges={dateRange}
                onChange={handleSelect}
                showSelectionPreview="false"
                dateDisplayFormat="dd/MM/yyyy"
            
              />
              <div className="flex w-full justify-end">
         
         
            <button type="button" onClick={handleClear} className="bg-white mr-4 min-w-[30px] rounded tracking-[unset] text-[#3C3C3C] leading-[6px] flex items-center justify-center px-4 py-1.5 hover:bg-atom_ghostwhite  focus-visible:border-0 focus-visible:outline  focus-visible:outline-2 focus-visible:outline-atom_battleshipgray disabled:bg-white disabled:text-[#D7D7DC] font-medium text-sm"> Cancel </button>


            <button
                    onClick={handleApply}
                      type="button"
                      className="relative inline-flex items-center gap-x-1 mr-4 rounded-md bg-atom_rojored px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-atom_rojored focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-atom_rojored"
                    >
                  
                      Apply
                    </button>
              </div>
            </div>
          )}
          </div>
           {/* Due Date  end */}
          

    {/* Read/unRead toggle start */}
    <div className="flex items-center  ml-3 ">
      <Switch
        checked={enabled}
        onChange={(event) => handleReadToggle(event)}
        className={classNames(
          enabled ? 'bg-atom_rojored' : 'bg-[#888]',
          'relative inline-flex h-3 w-7 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out '
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-4' : 'translate-x-0',
            'pointer-events-none inline-block h-2 w-2 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
      <label className="ml-2 text-[11px]">
        <span className="font-medium text-gray-900">Unread</span>{' '}
       
      </label>
    </div>
     {/* Read/unRead toggle end */}
      {/* Assigned on behalf  */}
      {selectedTab === 1 &&
           <div className="flex items-center ml-4 ">
            <Switch
              checked={onBehalfEnabled}
              onChange={(event) => handleAssignOnBehalfFilterChange(event)}
              className={classNames(
                onBehalfEnabled ? 'bg-atom_rojored' : 'bg-[#888]',
                'relative inline-flex h-3 w-7 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out '
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  onBehalfEnabled ? 'translate-x-4' : 'translate-x-0',
                  'pointer-events-none inline-block h-2 w-2 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
              />
            </Switch>
            <label as="span" className="ml-2 text-[11px]">
              <span className=" text-gray-900 font-medium">Assigned on behalf</span>{' '}
            
            </label>
    </div>}
     {/* Clear all button start*/}
     <button
      type="button"
      className="bg-white h-[33px] min-w-[30px] rounded tracking-[unset] ml-3 whitespace-nowrap
     text-atom_rojored leading-[6px] border border-atom_rojored flex 
     items-center justify-center px-3 py-1.5 hover:bg-atom_snowred  
     focus-visible:border-0 focus-visible:outline 
     focus-visible:outline-2 focus-visible:outline-atom_salmonpink
      disabled:border-[#D7D7DC] disabled:bg-white disabled:text-[#D7D7DC]
        font-medium text-[11px]"
            onClick={resetFilters}
            disabled={!filterModeOn}
          >
            Clear All
          </button>
          {/* Clear all button end*/}
          <Pagination
            currentPage={page}
            totalCount={count}
            pageSize={size}
            classes={`sm:hidden ml-auto`}
            onPageChange={(page) => handlePageChange(page)}
          />
        </form>
      </div>

      {/* filters end */}

      {/* filters start */}
      <div className="px-[10px] py-[10px] bg-white hidden border-t border-[#D7D7DC] sm:block">
        <form className="flex w-full items-center" action="">
          {/* search start */}
          <div className="relative rounded-md shadow-sm w-[100%]">
            <input
              type="text"
              name="search"
              id="search"
              className="block w-full sm:w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-atom_rojored sm:text-sm sm:leading-6"
              placeholder="Search"
              value={searchValue}
              onChange={(event) => filterTasksByIdNameDesc(event)}
            />
            <div className="pointer-events-none absolute right-0 flex items-center pr-3 top-[8px]">
              <svg
                className="h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            </div>
          </div>
          {/* search end */}
        </form>
      </div>

      <Transition show={open} as={Fragment}>
          <Dialog className="relative z-[99]" onClose={setOpen}>
            <TransitionChild
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-[#00000050] bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div className="fixed inset-0 overflow-hidden">
            <TransitionChild
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-y-full"
                  enterTo="translate-y-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-y-0"
                  leaveTo="translate-y-full"
                >
              <div className="pointer-events-none fixed top-[50%] bg-white rounded-t-md inset-x-0 bottom-0 flex justify-center" >
                
                  <DialogPanel className="pointer-events-auto w-full max-w-md">
                      <div className="box-border rounded-t-md bg-white border-b border-[#C4C4C4]">

                    
                        <div className="flex py-1 items-center px-4 w-full justify-between space-x-3">
                        <h3 className="font-semibold">Filter Task</h3>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="relative text-[#3C3C3C] hover:text-gray-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="relative">
                            <div className="bg-white">
                              <div className="px-[10px] py-[10px] bg-white border-[#D7D7DC]">
                                
                                <form
                                  className="relative w-full "
                                  action=""
                                >
                                  {/* assign by start */}
                                  {selectedTab === 0 && (
                                    <Combobox
                                      as="div"
                                      className="w-full mb-3"
                                      value={selectedAssignee}
                                      onChange={handleAssignedByFilterChange}
                                    >
                                      <div className="relative w-full rounded-md shadow-sm mr-3 ">
                                        <ComboboxInput
                                          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-atom_rojored sm:text-sm sm:leading-6 h-11"
                                          placeholder="Assigned By"
                                          onChange={handleInputChange}
                                          defaultValue=""
                                          displayValue={(selectedAssignee) =>
                                            selectedAssignee
                                              ? selectedAssignee === emailId
                                                ? "Me"
                                                : selectedAssignee
                                              : ""
                                          }
                                        />
                                        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                          <ChevronUpDownIcon
                                            className="h-6 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                        </ComboboxButton>

                                        {filteredAssignees &&
                                          filteredAssignees.length > 0 && (
                                            <ComboboxOptions className="absolute z-[99] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                              {filteredAssignees.map(
                                                (assignee, i) => (
                                                  <ComboboxOption
                                                    key={i}
                                                    value={assignee}
                                                    className={({ active }) =>
                                                      classNames(
                                                        "relative cursor-default select-none py-2 pl-3 pr-9",
                                                        active
                                                          ? "bg-atom_rojored text-white"
                                                          : "text-gray-900"
                                                      )
                                                    }
                                                  >
                                                    {({ active, selected }) => (
                                                      <>
                                                        <span
                                                          className={classNames(
                                                            "block truncate",
                                                            selected &&
                                                              "font-semibold"
                                                          )}
                                                          title={assignee}
                                                        >
                                                          {assignee === emailId
                                                            ? "Me"
                                                            : assignee}
                                                        </span>

                                                        {selected && (
                                                          <span
                                                            className={classNames(
                                                              "absolute inset-y-0 right-0 flex items-center pr-4",
                                                              active
                                                                ? "text-white"
                                                                : "text-atom_rojored"
                                                            )}
                                                          >
                                                            <CheckIcon
                                                              className="h-5 w-5"
                                                              aria-hidden="true"
                                                            />
                                                          </span>
                                                        )}
                                                      </>
                                                    )}
                                                  </ComboboxOption>
                                                )
                                              )}
                                            </ComboboxOptions>
                                          )}
                                      </div>
                                    </Combobox>
                                  )}
                                  {/* assign by end */}

                                  {/* assign to start */}
                                  {selectedTab === 1 && (
                                    <Combobox
                                      as="div" className="w-full mb-3"
                                      value={selectedAssignedTo}
                                      onChange={handleAssignedToFilterChange}
                                    >
                                      <div className="relative rounded-md shadow-sm mr-3 w-full">
                                        <ComboboxInput
                                          className="w-full min-w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-atom_rojored sm:text-sm sm:leading-6 h-11"
                                          placeholder="Assigned To"
                                          onChange={handleInput2Change}
                                          defaultValue=""
                                          displayValue={
                                            selectedAssignedTo
                                              ? selectedAssignedTo
                                              : ""
                                          }
                                        />
                                        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                          <ChevronUpDownIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                        </ComboboxButton>

                                        {filteredAssignedTos &&
                                          filteredAssignedTos.length > 0 && (
                                            <ComboboxOptions className="absolute z-[99] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                              {filteredAssignedTos.map(
                                                (user, i) => (
                                                  <ComboboxOption
                                                    key={i}
                                                    value={user}
                                                    className={({ active }) =>
                                                      classNames(
                                                        "relative cursor-default select-none py-2 pl-3 pr-9",
                                                        active
                                                          ? "bg-atom_rojored text-white"
                                                          : "text-gray-900"
                                                      )
                                                    }
                                                  >
                                                    {({ active, selected }) => (
                                                      <>
                                                        <span
                                                          className={classNames(
                                                            "block truncate",
                                                            selected &&
                                                              "font-semibold"
                                                          )}
                                                          title={user}
                                                        >
                                                          {user}
                                                        </span>

                                                        {selected && (
                                                          <span
                                                            className={classNames(
                                                              "absolute inset-y-0 right-0 flex items-center pr-4",
                                                              active
                                                                ? "text-white"
                                                                : "text-atom_rojored"
                                                            )}
                                                          >
                                                            <CheckIcon
                                                              className="h-5 w-5"
                                                              aria-hidden="true"
                                                            />
                                                          </span>
                                                        )}
                                                      </>
                                                    )}
                                                  </ComboboxOption>
                                                )
                                              )}
                                            </ComboboxOptions>
                                          )}
                                      </div>
                                    </Combobox>
                                  )}
                                  {/* assign to end */}

                                  {/* Status by start */}
                                 
          <div className="relative mb-3 w-full mr-3">
          
          <Select
            isMulti
            value={statusOptions.filter((option) => selectedStatuses.includes(option.value))}
            options={statusOptions}
            onChange={handleStatusFilterChangeMobile}
            placeholder="Select Status"
            styles={customStyles}
            className="multiSelectMobile"
            components={{
              DropdownIndicator: () => (
                <span className="flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              ),
              MultiValueRemove: ({ innerProps }) => (
                <span {...innerProps}>
                  <CheckIcon className="h-4 w-4" aria-hidden="true" />
                </span>
              ),
            }}
          />
          </div>
          {/* BU select start*/}
         
          <div className="relative mb-3 w-full mr-3">
          
          <Select
            isMulti
            value={buOptions.filter((option) => selectedBUs.includes(option.value))}
            options={buOptions}
            onChange={handleBuFilterChangeMobile}
            placeholder="Select Business unit"
            styles={customStyles}
            className="multiSelectMobile"
            components={{
              DropdownIndicator: () => (
                <span className="flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              ),
              MultiValueRemove: ({ innerProps }) => (
                <span {...innerProps}>
                  <CheckIcon className="h-4 w-4" aria-hidden="true" />
                </span>
              ),
            }}
          />
          </div>

          {/* BU select end */}

                        {/* Due Date  start */}
                        <div className="w-full relative mb-3">
                          <div
                            className="relative"
                            onClick={togglePicker}
                          >
                            <input
                              type="text"
                              className="w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-atom_rojored sm:text-sm sm:leading-6 h-11"
                              placeholder="Due Date"
                              value={
                                dateRange[0].startDate &&
                                dateRange[0].endDate
                                  ? `${UtilService.formatDate(
                                      dateRange[0].startDate
                                    )} - ${UtilService.formatDate(
                                      dateRange[0].endDate
                                    )}`
                                  : ""
                              }
                              readOnly
                            />
                            <div className="pointer-events-none cursor-pointer absolute inset-y-0 right-[0px] flex items-center pr-3">
                              <svg
                                className="h-4 w-4 text-gray-40"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.50002 0.833344V2.50001H11.5V0.833344H13.1667V2.50001H16.5C16.9603 2.50001 17.3334 2.87311 17.3334 3.33334V16.6667C17.3334 17.1269 16.9603 17.5 16.5 17.5H1.50002C1.03979 17.5 0.666687 17.1269 0.666687 16.6667V3.33334C0.666687 2.87311 1.03979 2.50001 1.50002 2.50001H4.83335V0.833344H6.50002ZM15.6667 9.16668H2.33335V15.8333H15.6667V9.16668ZM5.66669 11.6667V13.3333H4.00002V11.6667H5.66669ZM14 11.6667V13.3333H7.33335V11.6667H14ZM4.83335 4.16668H2.33335V7.50001H15.6667V4.16668H13.1667V5.83334H11.5V4.16668H6.50002V5.83334H4.83335V4.16668Z"
                                  fill="#3C3C3C"
                                ></path>
                              </svg>
                            </div>
                          </div>
                          {showPicker && (
                            <div
                              className="absolute z-[999] shadow-lg bg-white flex flex-col w-[95%] overflow-y-scroll pb-[450px]"
                              ref={dialogRef}
                            >
                              <DateRangePicker
                                ranges={dateRange}
                                onChange={handleSelect}
                                showSelectionPreview="false"
                                dateDisplayFormat="dd/MM/yyyy"
                                className="flex-col	"
                              />
                              <div className="flex w-full justify-end">
                                <button
                                  type="button"
                                  onClick={handleClear}
                                  className="bg-white mr-4 min-w-[30px] rounded tracking-[unset] text-[#3C3C3C] leading-[6px] flex items-center justify-center px-4 py-1.5 hover:bg-atom_ghostwhite  focus-visible:border-0 focus-visible:outline  focus-visible:outline-2 focus-visible:outline-atom_battleshipgray disabled:bg-white disabled:text-[#D7D7DC] font-medium text-sm"
                                >
                                  {" "}
                                  Cancel{" "}
                                </button>

                                <button
                                  onClick={handleApply}
                                  type="button"
                                  className="relative inline-flex items-center gap-x-1 mr-4 rounded-md bg-atom_rojored px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-atom_rojored focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-atom_rojored"
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                        {/* Due Date  end */}

                        {/* Read/unRead toggle start */}
                        <div className="flex items-start mb-5">
                        <div className="flex relative items-center mb-3">
                          <Switch
                            checked={enabled}
                            onChange={(event) =>
                              handleReadToggle(event)
                            }
                            className={classNames(
                              enabled
                                ? "bg-atom_rojored"
                                : "bg-[#888]",
                              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out "
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                enabled
                                  ? "translate-x-5"
                                  : "translate-x-0",
                                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                              )}
                            />
                          </Switch>
                          <label
                            as="span"
                            className="ml-3 text-sm"
                          >
                            <span className="font-medium text-gray-900">
                              Unread
                            </span>{" "}
                          </label>
                        </div>
                        {/* Read/unRead toggle end */}
                        {selectedTab === 1 &&
                        <div className="flex items-center relative  ml-5">
                          <Switch
                            checked={onBehalfEnabled}
                            onChange={(event) => handleAssignOnBehalfFilterChange(event)}
                            className={classNames(
                              onBehalfEnabled ? 'bg-atom_rojored' : 'bg-[#888]',
                              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out '
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                onBehalfEnabled ? 'translate-x-4' : 'translate-x-0',
                                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                              )}
                            />
                          </Switch>
                          <label as="span" className="ml-3 text-sm">
                            <span className=" text-gray-900">Assigned on behalf</span>{' '}
                          
                          </label>
                  </div>}
                  </div>

                                  {/* Clear all button start*/}
                                  <button
                                    type="button"
                                    className="w-full bg-white rounded tracking-[unset] whitespace-nowrap
     text-atom_rojored leading-[6px] border border-atom_rojored flex 
     items-center justify-center px-4 py-1.5 hover:bg-atom_snowred  
     focus-visible:border-0 focus-visible:outline
     focus-visible:outline-2 focus-visible:outline-atom_salmonpink
      disabled:border-[#D7D7DC] disabled:bg-white disabled:text-[#D7D7DC]
        font-medium text-sm h-11"
                                    onClick={resetFilters}
                                    disabled={!filterModeOn}
                                  >
                                    Clear All
                                  </button>
                                  {/* Clear all button end*/}
                                  <Pagination
                                    currentPage={page}
                                    totalCount={count}
                                    pageSize={size}
                                    classes={`sm:hidden`}
                                    onPageChange={(page) =>
                                      handlePageChange(page)
                                    }
                                  />
                                </form>
                              </div>
                            </div>
                        </div>
                        
                  </DialogPanel>
              </div>

              </TransitionChild>
            </div>
          </Dialog>
      </Transition>

      {/* filters end */}

      {/* {selectedTab == 0 && ( */}
      <div className="w-full z-[10] flex bg-[#f9f9fb] sm:hidden mt-[65px]">
        <div
          className={classNames(
            taskDetailModalOpen ? "w-3/5" : "w-full",
            "ml-[10px] mr-[5px] bg-white border border-[#C4C4C4] box-border overflow-auto h-[78vh] min-h-[320px]  relative shadow  "
          )}
        >
          <table className="min-w-full divide-y table-fixed">
            <thead className="">
              <tr>
               
              {/* <th
                  scope="col"
                  className="whitespace-pre-line sticky top-0 z-0 text-left text-neutral-700
                text-xs font-medium leading-none 
                 bg-[#efeff4] h-[38px] w-[74px] min-w-[74px] max-w-[74px] px-2.5 sm:pl-6"
                >
                  Task ID
                </th> */}
                <th
                  scope="col"
                  className="whitespace-pre-line w-[30%] z-[1]  min-w-[20%] max-w-[23%] sticky top-0 text-left text-neutral-700
                text-xs font-medium leading-none 
                 bg-[#efeff4] h-[38px] px-2.5 pl-12"
                >
                  Task Title
                </th>
                <th
                  scope="col"
                  className=" sticky top-0 text-left z-[1]  text-neutral-700 text-xs w-[10%]
                font-medium leading-none whitespace-nowrap bg-[#efeff4] h-[38px] px-2.5 sm:pl-6"
                >
                  Assigned {selectedTab == 0 ? "By" : "To"}
                </th>
                {(selectedTab === 1 && assignOnBehalfCol) &&
                <th className=" sticky top-0 text-left z-[1]  text-neutral-700 text-xs w-[10%]
                font-medium leading-none whitespace-nowrap bg-[#efeff4] h-[38px] px-2.5 sm:pl-6"
                >Assigned on Behalf</th>}
                <th
                  scope="col"
                  className=" sticky top-0 z-[1] text-left text-neutral-700 text-xs w-[10%]
                font-medium leading-none whitespace-nowrap bg-[#efeff4] h-[38px] px-2.5 sm:pl-6"
                >
                  BU
                </th>
                <th
                  scope="col"
                  className="sticky top-0 text-left z-[1] text-neutral-700
                text-xs font-medium leading-none whitespace-nowrap w-[20%]
                 bg-[#efeff4] h-[38px] px-2.5 sm:pl-6"
                >
                  Status
                </th>
                {!taskDetailModalOpen && (
                  <th
                    scope="col"
                    className=" sticky top-0 text-left z-[1] text-neutral-700
                text-xs font-medium leading-none whitespace-nowrap bg-[#efeff4] h-[38px] px-2.5 sm:pl-6"
                  >
                    Due Date
                  </th>
                )}
                {/* <th
                  scope="col"
                  className=" sticky top-0 z-0 text-left text-neutral-700
                text-xs font-medium leading-none whitespace-nowrap bg-[#efeff4] h-[38px] w-[15%] px-2.5 sm:pl-6"
                >
                  Created On
                </th> */}
                <th
                  scope="col"
                  className="sticky
                 top-0 right-0  text-center z-[1] text-neutral-700 text-xs
                 font-medium leading-none whitespace-nowrap bg-[#efeff4] w-[15%] h-[38px] px-2.5 sm:pl-6"
                >
                  <div>History</div>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 z-[2] bg-white">
              {tasks &&
                tasks.map((task, i) => (
                  <tr className="align-top group/item"  key={i} style = {{ backgroundColor: task.taskId === selectedTaskId ? '#d7e8ff' : 'white'}}>
                    
                    <td onClick={() => {markAsRead(task); handleTaskDetailModalChange(task)}} className="cursor-pointer  py-2 w-[23%] min-w-[20%] max-w-[23%] px-2.5 sm:pl-6 text-neutral-700 text-xs font-normal align-middle hover:underline">
                      {selectedTab === 1 &&
                      <div className="w-10 h-6 absolute inline-block align-top mt-1">
                      {task.assignedBy.toLowerCase() !== task.creator.toLowerCase() ?
                            <div className="w-6 h-6  leading-6 inline-block align-top mx-1 border-[1px] border-neutral-700 text-center rounded-[50%]">C</div>:''
                      }
                      </div>
                      }
                      <div
                        className={`overflow-hidden ml-10 inline-block group-hover/item:underline  text-left text-ellipsis text-xs ${task.isRead ? 'text-neutral-700' : 'text-atom_bleudefrance'} cursor-pointer`}
                      >
                       {task.taskId} | {task.title}<br/>
                       <span className="mt-[2px] block text-[#999]">{UtilService.formatDateToDateString(task?.creationDate)}</span>
                      </div>
                    </td>
                    <td onClick={() => {
                        markAsRead(task);
                        handleTaskDetailModalChange(task);
                      }}
                      className="whitespace-nowrap py-2 sticky px-2.5 sm:pl-6 cursor-pointer text-neutral-700 text-xs font-normal leading-none align-middle w-[10%]"
                    >
                      {selectedTab === 0 && emailId === task.assignedBy ? (
                        <div>Me</div>
                      ) : (
                        <div className="group sticky flex max-w-max flex-col items-center justify-center">
                          <div className="flex items-center justify-center rounded-full bg-[#3C3C3C] text-white text-xs w-[30px] h-[30px] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            {selectedTab === 0
                              ? UtilService.convertEmailToAbbreviation(task.assignedBy)
                              : UtilService.convertEmailToAbbreviation(task.assignedTo)}
                          </div>
                          <i className="ri-information-fill text-sm text-[#5a5a5a]"></i>
                          <div
                            className="absolute whitespace-normal w-[120px] left-1/2 z-[999]
                              [box-shadow:0px_0px_4px_0px_#00000025] top-[34px] ml-auto mr-auto bg-white
                              -translate-x-1/2 scale-0 transform rounded-lg text-xs font-medium
                              transition-all duration-500 group-hover:scale-100"
                          >
                            <div className="flex rounded-lg max-w-xs flex-col items-center relative w-full">
                              <div
                                className="rounded text-gray-800 p-2 text-center text-xs bg-white
                                after:bg-white after:content-[''] after:top-[-5px] 
                                after:left-[46%] after:rotate-[45deg] after:block after:absolute after:w-[10px] after:h-[10px]"
                              >
                                {selectedTab === 0 ? task.assignedBy : task.assignedTo}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </td>
                    {(selectedTab === 1 && assignOnBehalfCol) &&
                    <td onClick={() => {markAsRead(task); handleTaskDetailModalChange(task)}} className="cursor-pointer whitespace-nowrap py-2 px-2.5 align-middle w-[18%] sm:pl-6 text-neutral-700 text-xs font-normal leading-none">
                      {task.assignedBy}
                    </td>}
                    <td onClick={() => {markAsRead(task); handleTaskDetailModalChange(task)}} className="cursor-pointer whitespace-nowrap py-2 px-2.5 align-middle w-[18%] sm:pl-6 text-neutral-700 text-xs font-normal leading-none">
                      {task.business?.businessName}
                    </td>
                    <td className="whitespace-nowrap align-middle py-2 px-2.5 max-w-[160px] sm:pl-6 text-neutral-700 text-xs font-normal leading-none">
                    <div className="w-full inline-block max-w-[160px]">
                      <select
                        id="location"
                        name="location"
                        style={{
                          backgroundColor: task.status.color,
                          color: task.status.textColor,
                        }}
                        className={`bg-white mt-2 text-[12px] block w-full rounded-md border-0 py-1 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-atom_rojored sm:text-sm sm:leading-6`}
                        value={task.status._id}
                        onChange={(event) => {{
                          handleStatusChange(event, task);
                          markAsRead(task); 
                          taskDetailModalOpen && handleTaskDetailModalChange(task);
                          
                        }}}>
                        
                        {statusList.map((status) => (
                          <option
                            style={{
                              backgroundColor: status.color,
                              color: status.textColor,
                            }}
                            value={status._id}
                            key={status._id}
                          >
                            {" "}
                            {status.statusName}
                          </option>
                        ))}
                      </select>
                      </div>
                      {/* <span className="bg-[grey] bg-[yellow] bg-[lightgrey] bg-[blue] hidden"></span> */}
                    </td>
                    {!taskDetailModalOpen && (
                      <td
                        className={`whitespace-nowrap py-2 px-2.5 sm:pl-6 text-xs font-normal leading-none align-middle 
                    ${
                      UtilService.getDueDateText(task.dueDate)?.class === "past"
                        ? "bg-red-50"
                        : UtilService.getDueDateText(task.dueDate)?.class ===
                          "present"
                        ? "bg-green-50"
                        : ""
                    }`}
                      >
                        <p className="PlaceHolderText text-neutral-500 text-xs font-normal">
                          {UtilService.formatTimestampTodate(task.dueDate)}
                        </p>
                        <p
                          className={`PlaceHolderText text-xs font-bold ${
                            UtilService.getDueDateText(task.dueDate)?.class ===
                            "past"
                              ? "text-red-500"
                              : UtilService.getDueDateText(task.dueDate)
                                  ?.class === "present"
                              ? "text-green-700"
                              : "text-black"
                          }`}
                        >
                          {UtilService.getDueDateText(task.dueDate)?.text}
                        </p>
                      </td>
                    )}

                    

                    <td
                      className="border-stone-300 z-[1] right-0 whitespace-nowrap py-2 px-2.5 sm:pl-6
                 text-neutral-700 text-xs font-normal align-middle w-[15%]"
                    >
                      <div className="flex justify-center">
                        <button
                          className="text-xs text-atom_bleudefrance
                          flex items-center font-regular mr-1 cursor-pointer"
                          onClick={() => {
                            setopenHistory(true);
                            setHistoryTaskId(task.taskId);
                            markAsRead(task);
                             taskDetailModalOpen && handleTaskDetailModalChange(task);
                          }}
                        >
                          {/* <i className="text-base ri-external-link-line mr-1"></i> */}
                          <svg
                            className="text-base  mr-1"
                            width="17"
                            height="18"
                            viewBox="0 0 17 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.50008 0.666672C13.1024 0.666672 16.8334 4.39763 16.8334 9.00001C16.8334 13.6023 13.1024 17.3333 8.50008 17.3333C3.89771 17.3333 0.166748 13.6023 0.166748 9.00001H1.83341C1.83341 12.6819 4.81818 15.6667 8.50008 15.6667C12.182 15.6667 15.1667 12.6819 15.1667 9.00001C15.1667 5.31811 12.182 2.33334 8.50008 2.33334C6.2086 2.33334 4.18714 3.48945 2.98717 5.25021L5.16675 5.25001V6.91667H0.166748V1.91667H1.83341L1.83332 3.99932C3.3537 1.97563 5.77402 0.666672 8.50008 0.666672ZM9.33342 4.83334L9.33325 8.65417L12.0356 11.357L10.8571 12.5355L7.66658 9.34417L7.66675 4.83334H9.33342Z"
                              fill="#3C3C3C"
                            />
                          </svg>
                        </button>
                        {openHistory && historyTaskId === task.taskId && (
                          <History
                            open={openHistory}
                            setOpen={setopenHistory}
                            taskId={task.taskId}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
               
            </tbody>
          </table>
          {tasks.length === 0 &&
          <div className="h-[150px] text-lg font-medium text-center pt-[100px]">
              No Tasks Found
          </div>
              }
        </div>

        {/* Task Detail popup start*/}
        {taskDetailModalOpen && (
          <TaskDetail
            openDesktop={taskDetailModalOpen}
            setOpenDesktop={setTaskDetailModalOpen}
            taskId={selectedTaskId}
            setTaskId={setSelectedTaskId}
            selectedTab={selectedTab}
            onListUpdate={refetchTaskList}
          />
        )}
        {/* Task Detail popup end*/}
      </div>

      {/* Mobile view */}
      <div className="relative hidden sm:flex w-full">
        <div
          className={classNames(
            taskDetailModalOpen ? "" : "",
            "ml-[10px] mr-[10px] min-h-[150px] w-full"
          )}
        >
          {tasks &&
            tasks.map((task, i) => (
              <div
                className="align-top border border-[#C4C4C4] mb-2.5"
                key={i}
                style={{
                  backgroundColor:
                    task.taskId === selectedTaskId ? "aliceblue" : "white",
                }}>
                <div className="whitespace-pre-line py-2.5 px-2.5  text-neutral-700 text-xs font-normal align-middle hover:underline flex">
                  <div
                    scope="col"
                    className="text-left text-neutral-700 text-xs font-medium leading-none px-0.5 w-1/2"
                  >
                    Task ID
                  </div>
                  <button
                    onClick={() => {
                      markAsRead(task);
                      handleTaskDetailModalChangeMobile(task);
                      setOpenTaskDetailMobile(true);
                      setopenTaskId(task.taskId);
                    }}
                    className={`text-xs text-atom_bleudefrance flex items-center cursor-pointer`}
                  >
                    {task.taskId}
                    
                  </button>
                </div>
                <div className="py-2 w-[100%] flex px-2.5 text-neutral-700 text-xs font-normal align-middle hover:underline">
                  <div
                    scope="col"
                    className="text-left text-neutral-700 text-xs font-medium leading-none px-0.5 w-1/2"
                  >
                    Task Title
                  </div>
                  <button
                    onClick={() => {
                      markAsRead(task);
                      handleTaskDetailModalChangeMobile(task);
                      setOpenTaskDetailMobile(true);
                      setopenTaskId(task.taskId);
                    }}
                    className={`text-xs ${
                      task.isRead
                        ? "text-neutral-700"
                        : "text-atom_bleudefrance "
                    } flex text-left cursor-pointer w-1/2`}
                  >
                    {task.title}
                   
                  </button>
                </div>
                <div
                  className="whitespace-nowrap py-2 px-2.5
                text-neutral-700 text-xs font-normal leading-none align-middle w-[100%] flex"
                >
                  <div
                    scope="col"
                    className="text-left text-neutral-700 text-xs font-medium leading-none  px-0.5 flex items-center w-1/2"
                  >
                    Assigned By
                  </div>
                  {selectedTab === 0 && emailId === task.assignedBy && (
                    <div>Me</div>
                  )}
                  {!(selectedTab === 0 && emailId === task.assignedBy) && (
                    <div className="group relative flex max-w-max flex-col items-center justify-center">
                     
                      <div className="relative flex items-center justify-center rounded-full bg-[#3C3C3C] text-white text-xs w-[30px] h-[30px] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span className="absolute -inset-1.5" />

                        {selectedTab === 0
                          ? UtilService.convertEmailToAbbreviation(
                              task.assignedBy
                            )
                          : UtilService.convertEmailToAbbreviation(
                              task.assignedTo
                            )}
                      </div>
                      <i className="ri-information-fill text-sm text-[#5a5a5a]"></i>
                      <div
                        className="absolute whitespace-normal w-[120px] z-[999]
                            [box-shadow:0px_0px_4px_0px_#00000025] top-[34px] ml-auto mr-auto bg-white
                            -translate-x-1/2 scale-0 transform rounded-lg text-xs font-medium
                            transition-all duration-500 group-hover:scale-100"
                      >
                        <div className="flex rounded-lg max-w-xs flex-col items-center relative w-full ">
                          <div
                            className="rounded text-gray-800 p-2 text-center text-xs bg-white
                                after:bg-white after:content-[''] after:top-[-5px] 
                                after:left-[46%]
                                after:rotate-[45deg] after:block after:absolute after:w-[10px] after:h-[10px]"
                          >
                            {selectedTab === 0
                              ? task.assignedBy
                              : task.assignedTo}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {(selectedTab === 1 && assignOnBehalfCol) && (
                <div
                  className="whitespace-nowrap py-2 px-2.5
                text-neutral-700 text-xs font-normal leading-none align-middle w-[100%] flex"
                >
                  <div
                    scope="col"
                    className="text-left text-neutral-700 text-xs font-medium leading-none  px-0.5 flex items-center w-1/2"
                  >
                    Assigned on Behalf
                  </div>
                 
                     <p className="text-neutral-700 text-xs font-normal">
                     {task.assignedBy}
                     </p>
                  
                </div>
                )}

                <div className="whitespace-nowrap py-2 px-2.5 flex text-neutral-700 text-xs font-normal leading-none">
                <div
                    scope="col"
                    className="text-left text-neutral-700 text-xs font-medium leading-none  px-0.5 flex items-center w-1/2"
                  >
                    Business Unit
                  </div>  
                  <p className="text-neutral-700 text-xs font-normal">
                  {task.business?.businessName}
                  </p>
                    </div>
                <div className="whitespace-nowrap py-2 px-2.5 flex text-neutral-700 text-xs font-normal leading-none">
                  <div
                    scope="col"
                    className="text-left text-neutral-700 text-xs font-medium leading-none  px-0.5 flex items-center w-1/2"
                  >
                    Status
                  </div>
                  <select
                    id="location"
                    name="location"
                    style={{
                      backgroundColor: task.status.color,
                      color: task.status.textColor,
                    }}
                    className={`bg-white block rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-atom_rojored sm:text-sm sm:leading-4`}
                    value={task.status._id}
                    onChange={(event) => {
                      handleStatusChange(event, task);
                    }}
                  >
                    {statusList.map((status) => (
                      <option
                        style={{
                          backgroundColor: status.color,
                          color: status.textColor,
                        }}
                        value={status._id}
                        key={status._id}
                      >
                        {" "}
                        {status.statusName}
                      </option>
                    ))}
                  </select>
                </div>
                  <div
                    className={`whitespace-nowrap py-2 px-2.5 text-xs font-normal leading-none align-middle flex
                    ${
                      UtilService.getDueDateText(task.dueDate)?.class === "past"
                        ? "bg-red-50"
                        : UtilService.getDueDateText(task.dueDate)?.class ===
                          "present"
                        ? "bg-green-50"
                        : ""
                    }`}
                  >
                    <div className="w-1/2">
                    <div
                      scope="col"
                      className="text-left text-neutral-700 text-xs font-medium leading-none  px-0.5 w-1/2"
                    >
                      Due Date
                    </div>
                    <div className="PlaceHolderText text-neutral-500 text-xs font-normal">
                      {UtilService.formatTimestampTodate(task.dueDate)}
                    </div>
                    </div>
                    <p
                      className={`PlaceHolderText text-xs font-bold ${
                        UtilService.getDueDateText(task.dueDate)?.class ===
                        "past"
                          ? "text-red-500"
                          : UtilService.getDueDateText(task.dueDate)?.class ===
                            "present"
                          ? "text-green-700"
                          : "text-black"
                      }`}
                    >
                      {UtilService.getDueDateText(task.dueDate)?.text}
                    </p>
                  </div>

                <div
                  className={`whitespace-nowrap py-2 px-2.5 text-xs font-normal leading-none align-middle w-[100%] flex`}
                >
                  <div
                    scope="col"
                    className="text-left text-neutral-700 text-xs font-medium leading-none  px-0.5 w-1/2"
                  >
                    Created On
                  </div>
                  <p className="PlaceHolderText text-neutral-500 text-xs font-normal">
                    {UtilService.formatDateToDateString(task?.creationDate)}
                  </p>
                </div>

                <div className="flex px-2.5 py-2.5">
                  <div className="w-[50%]">
                  <button
                    onClick={() => {
                      setOpenTaskDetailMobile(true);
                      setopenTaskId(task.taskId);
                    }}
                    type="button"
                    className="relative inline-flex items-center gap-x-1 sm:gap-x-0 rounded-md bg-atom_rojored px-3 h-[33px] text-sm font-semibold text-white shadow-sm hover:bg-atom_rojored focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-atom_rojored"
                  >
                    View detail
                    
                  </button>
                  </div>
                  <div className="w-[50%]">
                  <button
                    type="button"
                    className="relative inline-flex items-center gap-x-1 sm:gap-x-0 rounded-md bg-atom_rojored px-3 h-[33px] text-sm font-semibold text-white shadow-sm hover:bg-atom_rojored focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-atom_rojored"
                    onClick={() => {
                      setopenHistoryMobile(true);
                      setHistoryTaskId(task.taskId);
                    }}
                  >
                    History
                    {openHistoryMobile && historyTaskId === task.taskId && (
                      <History
                        openHistoryMobile={openHistoryMobile}
                        setOpenHistoryMobile={setopenHistoryMobile}
                        taskId={task.taskId}
                      />
                    )}
                  </button>
                  </div>
                </div>
                {openTaskDetailMobile && openTaskId === task.taskId && (
                      <TaskDetail
                        openMobile={openTaskDetailMobile}
                        setOpenMobile={setOpenTaskDetailMobile}
                        taskId={task.taskId}
                      />
                    )}
              </div>
            ))}
        </div>
      </div>

      <div className="hidden sm:block items-center justify-between border-t border-gray-200 bg-white px-[10px] py-3 ">
       
        <Pagination
            currentPage={page}
            totalCount={count}
            pageSize={size}
            classes={`mobilePagination`}
            onPageChange={(page) => handlePageChange(page)}
          />
      </div>
      

      {/* )} */}
      {/* {selectedTab == 1 && (
        <>
          <div className="border atom_platinumgrey rounded-md w-full min-w-[100px] p-5 mt-3">
            <p className="text-sm font-medium text-atom_onxyblack">
              Content Tab 2
            </p>
          </div>
        </>
      )} */}

      <button
        type="button"
        className="rounded-full w-[55px] h-[55px] bg-red-600 p-1.5 fixed bottom-[55px] right-[10px] hidden sm:block"
        onClick={() => setOpen(true)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2.5em"
          height="2.5em"
          viewBox="0 0 24 24"
        >
          <path
            fill="white"
            d="M6.17 18a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2v-2zm6-7a3.001 3.001 0 0 1 5.66 0H22v2h-4.17a3.001 3.001 0 0 1-5.66 0H2v-2zm-6-7a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2V4z"
          />
        </svg>
      </button>
    </>
  );
}

export default Task;
