import { Fragment, useState, useEffect } from 'react'
import { Dialog,DialogPanel, TransitionChild,Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import DatePicker from "react-datepicker";
import Loader from "../../common/loader/loader";
import Notification, {showNotification} from "../../common/notification/notification";
import useAuthToken from '../../services/api';

export default function UpdateDueDate({open,setOpen, taskId, onUpdate}) {
  const { callApiWithToken } = useAuthToken();
  const [showLoader, setshowLoader] = useState(false);
  const [notification, setNotification] = useState(null);
  const [dueDateReason, setDueDateReason] = useState('');
  const [updatedDueDate, setUpdatedDueDate] = useState(null);
  const [task, setTask] = useState([]);
  const [title,setTitle] = useState('');
  const [description,setDescription] = useState('')
  const [files,setFiles] = useState([])
  const [creator, setCreator] = useState('');
  const [raisedBy,setRaisedBy] = useState('');
  const [assignedTo,setAssignedTo] = useState('');
  const [creationDate,setCreationDate] = useState('');
  const [modifiedDate,setModifiedDate] = useState('');
  const [status,setStatus] = useState(Number);
  const [businessId,setBusinessId] = useState('')

  const onDateChange = (date) => {
      setUpdatedDueDate(date);
      // console.log('date',date)
  };

  const fetchTaskDetail = async () => {
    // console.log('fetch tasks and edit task')
    
        // setShowLoader(true);
        const result = await callApiWithToken(
            `${process.env.REACT_APP_SERVER_URL}task/${taskId}`
        );
        // console.log('result.data',result.data)
        // setShowLoader(false);
        if(result.success) {
            let taskData = result.data;
            // console.log('taskData',taskData)
            setTitle(taskData.title)
            setDescription(taskData.description)
            if(taskData.attachments){
              setFiles(taskData.attachments.map(attachment => attachment))
            }else{
              setFiles(null)
            }
            // setSelectedBu(taskData.business.businessId);
            setCreator(taskData.creator)
            setRaisedBy(taskData.assignedBy)
            setAssignedTo(taskData.assignedTo)
            setCreationDate(taskData.creationDate)
            setModifiedDate(taskData.modifiedDate)
            setStatus(taskData.status._id)
            if (updatedDueDate) {
              setUpdatedDueDate(updatedDueDate)
              setTask({ ...taskData, dueDate: updatedDueDate });
              setDueDateReason(taskData.updatedDueDate.reason);
          } else {
              setTask(taskData); // Set taskData directly if no updated due date
          }
          if (taskData.business == null) {
              setBusinessId(null);
          }else {
              setBusinessId(taskData.business.businessId); // Set the business ID if it exists
          }
           
        } else {
            showNotification(setNotification, "error", "Unauthorised User!"); 
            setOpen(false);
            // navigate(`/tasks`);
        }
        setTask(result.data);
    
    
}

  // method to update Due Date
  const saveDueDate = async(event) => {
    event.preventDefault();
    if(!updatedDueDate) {
      showNotification(setNotification, "error", "Please select a date!");
      return;
    }
    if(dueDateReason === '') {
      showNotification(setNotification, "error", "Due Date Reason is mandatory!");
      return;
    }
    let requestBody = {
      updatedDueDate: {value: updatedDueDate.getTime(), 
      reason: dueDateReason},
      taskId:taskId,
      title: title,
      description: description,
      dueDate: updatedDueDate.getTime(),
      attachments: files,
      creator: creator,
      assignedTo:assignedTo,
      assignedBy:raisedBy,
      creationDate:creationDate,
      modifiedDate:modifiedDate,
      isRead: true,
      status: status,
      businessId:businessId,
    }
    setshowLoader(true);
    const res = await callApiWithToken(
    `${process.env.REACT_APP_SERVER_URL}task/update/${taskId}`,
    {
        method: "PUT",
        body: JSON.stringify(requestBody),
    }
    );
    setshowLoader(false);
    if (res.success && res.data) {
      showNotification(setNotification, "success", "Due date updated successfuly!");
      setTimeout(() => {setOpen(false)}, 2000);
      onUpdate();
    } 
  }
  useEffect(() => {
    fetchTaskDetail()
  }, [taskId])
  

  return (
    <>
    
    {showLoader && (
    <div className="fixed h-screen w-screen bg-[#00000024] z-[1000] top-0 left-0 ">
        <div className="h-full w-full flex items-center justify-center">
        <Loader visible={showLoader} />
        </div>
    </div>
    )}
    {/* <Transition.Root show={open} as={Fragment}>
      <Dialog className="relative z-10" onClose={setOpen}>
      <Notification type={notification?.type} message={notification?.message} />
      <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#00000020] bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-[455px]">
                  <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1">
                     
                      <div className="h-[53px] box-border  bg-white">
                        <div className="h-full flex items-center  px-4 justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              Update Due Date
                            </Dialog.Title>
                          </div>
                          <div className="flex h-7 items-center">
                            <button onClick = {(event) => saveDueDate(event)} className="inline-flex mr-5 items-center justify-center rounded-md bg-atom_rojored px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-atom_rojored focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-atom_rojored">
                              <svg
                                className="mr-2"
                                width="14"
                                height="15"
                                viewBox="0 0 14 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.25 12.75V8.25H10.75V12.75H12.25V4.37132L10.1287 2.25H1.75V12.75H3.25ZM1 0.75H10.75L13.75 3.75V13.5C13.75 13.9142 13.4142 14.25 13 14.25H1C0.58579 14.25 0.25 13.9142 0.25 13.5V1.5C0.25 1.08579 0.58579 0.75 1 0.75ZM4.75 9.75V12.75H9.25V9.75H4.75Z"
                                  fill="white"
                                />
                              </svg>
                              Save
                            </button>
                            <button
                              type="button"
                              className="relative text-[#3C3C3C] hover:text-gray-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>

                     
                      <div className="space-y-6 py-6 sm:space-y-0 sm:py-0">
                        <div className=" px-4 sm:px-6 sm:pt-3 bg-[#f9f9fb]">
                          <div className="mb-2">
                            <label
                              htmlFor="project-description"
                              className="block text-sm font-medium leading-6 text-[#3C3C3C] sm:mt-1.5"
                            >
                              Mention Reason
                            </label>
                          </div>
                          <div>
                            <textarea
                              id="project-description"
                              name="project-description"
                              placeholder="Description"
                              rows={7}
                              className="block w-full placeholder:text-[#3C3C3C] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value = {dueDateReason}
                              onChange={(event) => setDueDateReason(event.target.value)}
                            />
                          </div>
                        </div>
                        <div className=" px-4  sm:px-6 sm:pt-3 bg-[#f9f9fb]">
                          <div className="mb-2">
                            <label
                              htmlFor="project-description"
                              className="block text-sm font-medium leading-6 text-[#3C3C3C] sm:mt-1.5"
                            >
                              Select Due Date
                            </label>
                          </div>
                          <div className="customDatePicker">
                            <DatePicker
                              dateFormat="dd/MM/YYYY" placeholderText="Select Due Date" selected={updatedDueDate} onChange={onDateChange}  minDate={new Date()}
                              inline
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root> */}
    <Transition show={open} appear={true}>
      <Dialog className="relative z-10" onClose={setOpen}>
      <Notification type={notification?.type} message={notification?.message} />
      <TransitionChild
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#00000020] bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              
              <TransitionChild
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="pointer-events-auto w-[455px]">
                  <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1">
                      {/* Header */}
                      <div className="h-[53px] box-border  bg-white">
                        <div className="h-full flex items-center  px-4 justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              Update Due Date
                            </Dialog.Title>
                          </div>
                          <div className="flex h-7 items-center">
                            <button onClick = {(event) => saveDueDate(event)} className="inline-flex mr-5 items-center justify-center rounded-md bg-atom_rojored px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-atom_rojored focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-atom_rojored">
                              <svg
                                className="mr-2"
                                width="14"
                                height="15"
                                viewBox="0 0 14 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.25 12.75V8.25H10.75V12.75H12.25V4.37132L10.1287 2.25H1.75V12.75H3.25ZM1 0.75H10.75L13.75 3.75V13.5C13.75 13.9142 13.4142 14.25 13 14.25H1C0.58579 14.25 0.25 13.9142 0.25 13.5V1.5C0.25 1.08579 0.58579 0.75 1 0.75ZM4.75 9.75V12.75H9.25V9.75H4.75Z"
                                  fill="white"
                                />
                              </svg>
                              Save
                            </button>
                            <button
                              type="button"
                              className="relative text-[#3C3C3C] hover:text-gray-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Divider container */}
                      <div className=" sm:space-y-0 sm:py-0 bg-[#f9f9fb]">
                        <div className="px-4 sm:px-6 sm:pt-3 ">
                          <div className="mb-2 pt-2">
                            <label
                              htmlFor="project-description"
                              className="block text-sm font-medium leading-6 text-[#3C3C3C] sm:mt-1.5"
                            >
                              Mention Reason
                            </label>
                          </div>
                          <div>
                            <textarea
                              id="project-description"
                              name="project-description"
                              placeholder="Description"
                              rows={7}
                              className="block w-full placeholder:text-[#3C3C3C] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value = {dueDateReason}
                              onChange={(event) => setDueDateReason(event.target.value)}
                            />
                          </div>
                        </div>
                        <div className=" px-4  sm:px-6 sm:pt-3 bg-[#f9f9fb]">
                          <div className="mb-2">
                            <label
                              htmlFor="project-description"
                              className="block text-sm font-medium leading-6 text-[#3C3C3C] sm:mt-1.5"
                            >
                              Select Due Date
                            </label>
                          </div>
                          <div className="customDatePicker">
                            <DatePicker
                              dateFormat="dd/MM/YYYY" placeholderText="Select Due Date" selected={updatedDueDate} onChange={onDateChange}  minDate={new Date()}
                              inline
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Action buttons */}
                  </form>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
    </>
  );
}
